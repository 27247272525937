/* Base Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f6f8;
  color: #333;
}

/* Header Styling */
.header {
  background-color: #4a90e2;
  padding: 1rem 2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
}

.header .nav-links a {
  color: #fff;
  margin-left: 1rem;
  text-decoration: none;
  font-size: 1rem;
}

.header .nav-links a:hover {
  text-decoration: underline;
}

/* Footer Styling */
.footer {
  background-color: #4a90e2;
  padding: 1rem 2rem;
  color: #fff;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* Main Container */
.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 120px); /* Adjust based on header and footer height */
  padding: 2rem;
}

/* Chat and Content Sections */
.main-content {
  display: flex;
  flex: 1;
  gap: 2rem;
  flex-wrap: wrap;
}

.chat-section, .trend-section, .other-section {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-section {
  flex: 1 1 300px;
  max-width: 400px;
}

.trend-section {
  flex: 2 1 600px;
  min-width: 300px;
}

.other-section {
  flex: 1 1 300px;
  min-width: 250px;
}

/* Chat Component Mobile Adjustments */
.chat-message-content {
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.chat-section {
  padding: 1rem; /* Reduce padding for mobile */
}

.chat-section .scroll {
  max-height: 60vh; /* Limit height for mobile */
}

/* Typography Enhancements */
h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #4a90e2;
}

p {
  line-height: 1.6;
}

/* Button Styling */
button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #357ab8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .chat-section, .trend-section, .other-section {
    max-width: 100%;
    padding: 1rem; /* Adjust padding for mobile */
  }

  .header, .footer {
    padding: 1rem;
  }

  .header h1 {
    font-size: 1.25rem;
  }

  .header .nav-links a {
    margin-left: 0.5rem;
    font-size: 0.9rem;
  }

  /* Chat Specific Adjustments */
  .chat-message-content {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  button {
    padding: 0.5rem 1rem; /* Smaller button size for mobile */
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.chat-message-content ul, .chat-message-content ol {
  margin-left: 20px; /* or whatever value provides a suitable indent */
}

.chat-message-content ul li, .chat-message-content ol li {
  list-style-position: inside; /* or 'outside' depending on the desired effect */
}

.ql-indent-1 {
  padding-left: 20px;
}
.ql-indent-2 {
  padding-left: 40px;
}
.ql-indent-3 {
  padding-left: 60px;
}
.ql-indent-4 {
  padding-left: 80px;
}
.ql-indent-5 {
  padding-left: 100px;
}
/* Add more if you need deeper levels of indentation */

.ql-editor ul,
.ql-editor ol {
  padding-left: 20px; /* Adjust to ensure bullets have enough padding to be visible */
  list-style-type: disc !important; /* Force bullets to be displayed for unordered lists */
}

.ql-editor li {
  padding-left: 10px; /* Ensure list items have padding */
}
